//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapMutations } from 'vuex'
import VClamp from 'vue-clamp'
import panstoreMixin from '@/mixins'
export default {
  components: {
    VClamp
  },
  mixins: [panstoreMixin],
  data () {
    return {
      qty: 1,
      selected: [],
      selectedImage: 0,
      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      },
      sliderArrowPresale: {
        left: '<img src="/img/icon/chevron1-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron1-right.svg" alt=""/>'
      },
      onSubmit: false,
      is_wishlisted: false,
      total_wishlist: 0,
      onShow: false,
      loadingZoom: true,
      zoom: true,
      share: false,
      currentLocation: 'Kota Jakarta'
    }
  },
  computed: {
    ...mapState('Product', ['product', 'productPreSaleSession', 'productAffiliate']),
    ...mapState('Voucher', ['vouchers']),
    ...mapState('Base', ['isError']),
    ...mapState('User/address', ['location']),
    preOrderShow () {
      if (this.indexVariant === -1) {
        return this.product.is_preorder
      }

      return !!this.product.details[this.indexVariant].is_preorder
    },
    countdownShow () {
      if (this.indexVariant === -1 && this.countDownFlashsale) {
        return true
      }
      return this.isDetailFlashsale && this.product.details[this.indexVariant].event_sale_session
    },
    totalTypeVariant () {
      return this.product.variant_categories.length || 0
    },
    validVariant () {
      let available = true
      this.selected.map((select) => {
        if (!select.length) {
          available = false
        }
      })
      return available
    },
    countDownFlashsale () {
      if (this.product.event_ongoing.flash_sale && this.product.event_ongoing.flash_sale.length) {
        if (
          this.getFlashTime(
            this.product.event_ongoing.flash_sale[0].session
              .date_start
          )
        ) {
          return this.countdownTime(
            this.product.event_ongoing.flash_sale[0].session.date_end
          )
        } else {
          return this.countdownTime(
            this.product.event_ongoing.flash_sale[0].session
              .date_start
          )
        }
      } else {
        return 0
      }
    },
    selectedVariant () {
      if (this.product.type === 'combination') {
        const temp = this.selected.join(',')
        const variant = this.product.details.filter((detail) => {
          return temp === detail.name
        })
        if (variant.length) {
          this.SET_ERROR(false)
          this.setSelectedDetails(variant[0])
          return variant[0].id
        } else {
          return null
        }
      } else {
        this.SET_ERROR(false)
        this.setSelectedDetails(this.product.details[0])
        return this.product.details[0].id
      }
    },
    productStock () {
      const temp = this.selected.filter((select) => {
        return select.length
      })
      if (this.selectedVariant) {
        return this.product.details.filter((detail) => {
          return detail.id === this.selectedVariant
        })[0].stock
      } else if (temp.length && !this.selectedVariant) {
        return -1
      } else {
        return this.product.stock
      }
    },
    imageProductOriginal () {
      const image = []

      const imageProduct = this.product.files.filter((file) => {
        return file.variant === 'original' && file.type === 'image'
      })
      imageProduct.map((img) => {
        image.push({ ...img, variant: null })
      })
      if (this.product.type !== 'simple') {
        this.product.details.map((detail) => {
          detail.files.map((file) => {
            if (file.variant === 'original' && file.type === 'image') {
              image.push({ ...file, variant: detail.id })
            }
          })
        })
      }
      return image
    },
    imageProductThumbnail () {
      const image = []
      const fileName = []
      const imageProduct = this.product.files.filter((file) => {
        return file.variant === 'thumbnail' && file.type === 'image'
      })
      imageProduct.map((img) => {
        fileName.push(img.filename)
        image.push({ ...img, variant: null })
      })
      if (this.product.type !== 'simple') {
        this.product.details.map((detail) => {
          detail.files.map((file) => {
            if (file.variant === 'thumbnail' && file.type === 'image') {
              image.push({ ...file, variant: detail.id })
              fileName.push(file.filename)
            }
          })
        })
      }
      return image
    },
    getPrice () {
      let datas = []
      let price = this.product.price
      let discounted = this.product.discounted_price
      if (this.selectedVariant) {
        datas = this.product.details.filter((detail) => {
          return this.selectedVariant === detail.id
        })[0]
        price = datas.price
        discounted = datas.discounted_price
      }
      return {
        price: this.formatRupiah(price.toString(), 'Rp'),
        show: !this.selectedVariant || price !== discounted
      }
    },
    getDiscountPrice () {
      let price = this.product.discounted_price
      if (this.selectedVariant) {
        price = this.product.details.filter((detail) => {
          return this.selectedVariant === detail.id
        })[0].discounted_price
      }
      return this.formatRupiah(price.toString(), 'Rp')
    },
    getDiscountValue () {
      let discount = this.product.discount_values
      if (this.selectedVariant) {
        discount = this.product.details.filter((detail) => {
          return this.selectedVariant === detail.id
        })[0].discount_values
      }
      return discount.join('% + ') + '%'
    },
    handleShowDiscountBadge () {
      let valid = this.product.discount_values
      if (this.selectedVariant) {
        valid = this.product.details.filter((variant) => {
          return variant.id === this.selectedVariant
        })[0].discount_values
      }
      return valid.length
    },
    showStock () {
      if (this.product.type === 'combination') {
        return this.validVariant
      } else {
        return true
      }
    },
    indexVariant () {
      return this.product.details.findIndex((variant) => {
        return variant.id === this.selectedVariant
      })
    },
    isDetailFlashsale () {
      if (this.indexVariant >= 0) {
        if (this.product.details[this.indexVariant].event_sale_session) {
          return this.product.details[this.indexVariant].event_sale_session
            .event_sale_id
        }
      }
      return false
    },
    isProductFlashsale () {
      return this.product.event_ongoing.flash_sale && this.product.event_ongoing.flash_sale.length
    },
    isEventsale () {
      if (this.indexVariant >= 0) {
        if (this.product.details[this.indexVariant].event_ongoing.event_sale) {
          return this.product.details[this.indexVariant].event_ongoing
            .event_sale
        }
      }
      return false
    },
    isPresale () {
      if (this.indexVariant > -1) {
        if (this.product.details[this.indexVariant].event_ongoing.pre_sale) {
          return this.product.details[this.indexVariant].event_ongoing.pre_sale
        }
      } else if (this.indexVariant === -1) {
        const findIdx = this.product.details.findIndex((variant) => {
          return variant.event_ongoing.pre_sale
        });
        if (findIdx > -1) {
          return this.product.details[findIdx].event_ongoing.pre_sale
        }
      }
      return false
    },
    countdownDays () {
      return this.isPresale ? this.setCountdown(this.formatYMD(this.isPresale[0].session.date_start), this.formatYMD(this.isPresale[0].session.date_end)) : null
    },
    isPresaleStarted () {
      const dateNow = new Date();
      const dateStart = new Date(this.formatYMD(this.isPresale[0].session.date_start));
      const dateEnd = new Date(this.formatYMD(this.isPresale[0].session.date_end));

      return dateNow >= dateStart && dateNow <= dateEnd
    },
    dateStartEnd () {
      const dateNow = new Date();
      const dateStart = new Date(this.formatYMD(this.isPresale[0].session.date_start));
      const dateEnd = new Date(this.formatYMD(this.isPresale[0].session.date_end));

      if (dateNow >= dateStart && dateNow <= dateEnd) {
        return 'Berakhir';
      } else {
        return 'Dimulai';
      }
    }
  },
  watch: {
    qty () {
      this.setQty(this.qty)
    },
    location () {
      this.currentLocation = this.location.value
    },
    selectedVariant () {
      this.getPresaleSession()
    },
    sessionDateEnd () {
    this.calculateDaysRemaining();
  }
  },
  created () {
    this.getListVoucher()
    this.getPresaleSession()
    this.is_wishlisted = this.product.is_wishlisted
    this.total_wishlist = this.product.total_wishlist
    this.currentLocation = this.location.value
    this.product.variant_categories.map((variant) => {
      this.selected.push('')
    })
  },
  methods: {
    ...mapActions('Cart', ['addCart', 'getCarts', 'getCartsNavbar']),
    ...mapMutations('Cart', [
      'setSelectedProduct',
      'setSelectedVariant',
      'setQty'
    ]),
    ...mapMutations('Product', ['setSelectedDetails', 'setProductPreSaleSession', 'setProductAffiliate']),
    ...mapMutations('Base', ['SET_ERROR']),
    ...mapMutations('Voucher', ['setVoucher', 'setToggleDetail']),
    ...mapActions('User', ['toggleWishlist']),
    ...mapActions('Product', ['getProductWishlist', 'getProductsPreSaleSession']),
    ...mapActions('Voucher', ['getVouchers']),
    setCountdown (startDate, endDate) {
      const now = new Date();

      if (now < new Date(startDate)) {
          const timeDiff = new Date(startDate) - now;
          return this.formatTimeDiff(timeDiff);
      }

      if ((now >= new Date(startDate) && now <= new Date(endDate))) {
          const timeDiff = new Date(endDate) - now;
          return this.formatTimeDiff(timeDiff);
      }

      return null;
    },
    formatTimeDiff (timeDiff) {
      const days = timeDiff / (1000 * 60 * 60 * 24);
      const hours = Math.floor(timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60);
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

      if (days > 2) {
          return `${Math.floor(days)} hari lagi`
      } else if (days > 1.5) {
          return `${Math.floor(days + 1)} hari lagi`
      } else if (days >= 1 && days < 1.5) {
        return `${Math.floor(days)} hari lagi`
      } else if (hours >= 1) {
          return `${Math.floor(hours + 1)} jam lagi`
      } else if (minutes >= 0) {
          return `${Math.floor(minutes + 1)} menit lagi`
      } else {
        return '-'
      }
    },
    two_digits (value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    },
    async getListVoucher () {
      const detailId = []
      this.product.details.map((detail) => {
        detailId.push(detail.id)
      })
      try {
        await this.getVouchers({
          data: {
            product_detail_id: detailId
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    onClickVoucher (data) {
      this.setVoucher(data)
      this.setToggleDetail(true)
    },
    handleChangeVariant () {
      if (this.selectedVariant) {
        const index = this.imageProductOriginal.findIndex((img) => {
          return img.variant === this.selectedVariant
        })
        if (index !== -1) {
          this.selectedImage = index
        }
      }
    },

    handleChangeImage (index) {
      if (!this.loadingZoom) {
        this.selectedImage = index
        this.loadingZoom = true
        this.zoom = false
        setTimeout(() => (this.zoom = true), 800)
      }
    },
    getVariantLabel (variant) {
      if (variant === 'Color') {
        return 'Pilihan Warna'
      } else if (variant === 'Size') {
        return 'Pilihan Ukuran'
      } else if (variant === 'Flavor') {
        return 'Pilihan Rasa'
      } else {
        return null
      }
    },
    getLabelStock (stock) {
      if (stock > 5) {
        return `Tersisa ${stock} buah`
      } else if (stock > 0 && stock <= 5) {
        return `${stock} Stok terakhir`
      } else if (stock === -1) {
        return 'Stok habis'
      } else {
        return 'Stok habis'
      }
    },
    async addToCart () {
      this.onSubmit = true
      if (this.selectedVariant) {
        await this.setSelectedProduct(this.product)
        const productId = this.selectedVariant
        const payload = Object.assign({
          data: {
            product_detail_id: productId,
            quantity: this.qty,
            session_id: this.$cookies.get('session_id')
          }
        })

        try {
          const resp = await this.addCart(payload)
          if (resp.data.status_code === 201) {
            await this.setSelectedVariant(productId)
            await this.handleGetCart()
            this.$refs.showAlert.click()
          }
        } catch (error) {
          console.log(error)
          if (this.isDetailFlashsale) {
            this.$message.error(error.response.data.message)
          }
          this.onSubmit = false
        }
      } else {
        this.$message.error('Pilih variasi terlebih dahulu!')
      }
      this.onSubmit = false
    },
    async handleGetCart () {
      const payload = {
        session_id: this.$cookies.get('session_id')
      }
      try {
        await this.getCartsNavbar({ params: payload })
      } catch (error) {
        console.log(error)
      }
    },
    inputQty (value) {
      if (value > this.productStock) {
        this.qty = this.productStock
      }
      if (Number(value) < 0) {
        this.qty = 1
      }
    },
    getFlashTime (start) {
      const startTime = new Date(this.formatYMD(start))
      if (startTime.getTime() < new Date().getTime()) {
        return true
      } else {
        return false
      }
    },
    countdownEnd () {
      if (this.isDetailFlashsale) {
        window.location = window.location.href
      }
    },
    async handleToggleWishlist () {
      if (this.$auth.loggedIn) {
        try {
          const resp = await this.toggleWishlist({
            data: {
              product_id: [this.product.id]
            }
          })
          if (resp.status === 200) {
            await this.getProductWishlist({
              data: { session_id: this.$cookies.get('session_id') }
            })
            this.is_wishlisted = !this.is_wishlisted
            if (this.is_wishlisted) {
              this.total_wishlist++
            } else {
              this.total_wishlist--
            }
            this.$message({
              message: `Produk berhasil ${
                !this.is_wishlisted ? 'dihapus dari ' : 'ditambahkan ke'
              } Wishlist.`,
              type: 'success'
            })
          }
        } catch (error) {
          console.log(error)
          this.$message({
            message: 'Oops, Terjadi kesalahan.',
            type: 'error'
          })
        }
      }
      // else {
      //   this.$alert('Silahkan login terlebih dahulu!')
      // }
    },
    async getPresaleSession () {
      const payload = {
        data: {
          purpose: 'pre_sale_product_detail',
          product_detail_ids: [],
          event_sale_id: this.checkArray(this.isPresale) ? this.isPresale[0].id : '',
          exception_ids: this.checkArray(this.isPresale) && this.isPresaleStarted ? [this.isPresale[0].session.id] : []
        }
      };
      if (this.indexVariant < -1) {
        payload.data.product_detail_ids = [this.product.details[this.indexVariant].id]
      } else {
        const dataIds = this.product.details.findIndex((detail) => {
          return !!detail.event_ongoing.pre_sale.length
        })
        if (dataIds > -1) {
          payload.data.product_detail_ids = [this.product.details[dataIds].id]
        }
      }
      try {
         await this.getProductsPreSaleSession(payload)
      } catch (error) {
        console.log(error)
      }
    },
    getStyleBadgeScroll (color) {
      let mainColor = 'color-main';

      switch (color) {
        case 'incoming':
          mainColor = 'color-main';
          break;
        case 'expired':
          mainColor = 'color-dark';
          break;
      }

      return mainColor;
    },
    getStyleTextBadge (color) {
      let mainColor = 'color-main';

      switch (color) {
        case 'incoming':
          mainColor = 'color-main';
          break;
        case 'expired':
          mainColor = 'color-dark';
          break;
      }

      return mainColor;
    },
    getEventStatusText (result) {
      let text = 'Sedang Berlangsung';

      switch (result) {
        case 'incoming':
          text = 'Akan Datang';
          break;
        case 'expired':
          text = 'Sudah Terlewat';
          break;
      }

      return text;
    },
    getImage (type) {
      if (type === 'discount') {
        return require('@/static/img/home/cart/voucher-tag.svg')
      } else {
        return require('@/static/img/home/cart/voucher-truck.svg')
      }
    },
    handleGotoSection (id) {
      const element = document.getElementById(id)
      element.scrollIntoView({
        behavior: 'smooth'
      })
    },
    onLoadedImage (event) {
      this.loadingZoom = false
    }
  }
}
